<div class="container">
    <div class="mb-4">
        <h2 class="mb-0">{{ "it.portal.login.system-access" | translate }}</h2>
    </div>
    <div class="row">
        <div class="col-lg-5">
            <p class="mb-5">{{ "it.portal.login.intro" | translate }}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-7">
            <div class="card-wrapper">
                <div class="card">
                    <div class="card-body">
                        <div class="details-container d-block">
                            <div class="details-content w-100">
                                <h4 class="">{{ user.nome }} {{ user.cognome }}</h4>
                                <form [formGroup]="loginForm" class="pt-5" (ngSubmit)="onLogin()">
                                    <it-input
                                        type="text"
                                        [label]="'it.portal.users.cf' | translate"
                                        formControlName="cf"
                                        uppercase
                                    ></it-input>
                                    @if (authService.isUserLoggedIn && roles.length) {
                                    <h4 class="pb-3">{{ "it.portal.login.select-role" | translate }}</h4>
                                    <it-list class="custom-list">
                                        @for (item of roles; track item.uid) {
                                        <it-list-item iconLeft="true" class="list-data">
                                            <div class="flex-grow-1">
                                                <div class="list-title">
                                                    <div class="text me-4">{{ item.ruolo.nome }}</div>
                                                </div>
                                                @if(!item.isTechnician){
                                                <div class="list-info">
                                                    <span>{{ item.ente.nome }}</span>
                                                </div>
                                                }
                                            </div>
                                            <ng-container multiple>
                                                <it-radio-button
                                                    [value]="item.uid"
                                                    formControlName="utenteEnteRuolo"
                                                ></it-radio-button>
                                            </ng-container>
                                        </it-list-item>
                                        }
                                    </it-list>
                                    }
                                    <div class="row mt-5">
                                        <div class="form-group col text-end">
                                            <button itButton="primary" type="submit">
                                                {{ "it.portal.login.label" | translate }}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
