import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandlerFn,
    HttpInterceptorFn,
    HttpRequest,
    HttpResponse,
    HttpStatusCode,
} from "@angular/common/http";
import { inject, ErrorHandler } from "@angular/core";
import { ConfirmDeleteComponent } from "@app/components/modals/confirm-delete/confirm-delete.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, catchError, EMPTY, filter, map, Observable, switchMap, take, throwError } from "rxjs";
import { AuthService } from "../services/auth.service";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { LoadingService } from "@app/services/loading.service";

export const httpInterceptor: HttpInterceptorFn = (req, next) => {
    const authService = inject(AuthService);
    const router = inject(Router);
    const errorHandler = inject(ErrorHandler);
    const loadingService = inject(LoadingService);
    const authToken = authService.getToken();
    const refreshToken = authService.getRefreshToken();
    const loginApiUrl = `${environment.servicesAuthUrl}/login`;
    const userApiUrl = `${environment.servicesAuthUrl}/utente`;
    const refreshApiUrl = `${environment.servicesAuthUrl}/refresh`;
    let isRefreshing = false;
    let refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    loadingService.setLoading(true, req.url);

    if (authToken && refreshToken && !req.headers.get("Authorization"))
        req = addTokenHeader(req, req.url === refreshApiUrl ? refreshToken : authToken);

    if (req.method === "DELETE" && !req.url.includes(environment.accessmanager.logoutUri)) {
        loadingService.setLoading(false, req.url);
        const modalRef = inject(NgbModal).open(ConfirmDeleteComponent);
        return modalRef.closed.pipe(
            switchMap((result: boolean) => {
                return result ? next(req).pipe(catchError(errorCatcher)) : EMPTY;
            })
        );
    }

    function handle401Error(request: HttpRequest<any>, next: HttpHandlerFn) {
        if (!isRefreshing) {
            isRefreshing = true;
            refreshTokenSubject.next(null);
            const refreshToken = authService.getRefreshToken();
            if (refreshToken)
                return authService.refreshToken().pipe(
                    switchMap((payload: any) => {
                        isRefreshing = false;
                        refreshTokenSubject.next(payload.token);
                        return next(addTokenHeader(request, payload.token));
                    }),
                    catchError((err) => {
                        isRefreshing = false;
                        authService.logout();
                        router.navigate(["/"]);
                        return EMPTY;
                    })
                );
            else router.navigate(["accesso"]);
        }
        return refreshTokenSubject.pipe(
            filter((refreshToken) => refreshToken !== null),
            take(1),
            switchMap((refreshToken) => next(addTokenHeader(request, refreshToken)))
        );
    }

    function errorCatcher(httpErrorResponse: HttpErrorResponse, _: Observable<HttpEvent<any>>) {
        loadingService.setLoading(false, req.url);
        if (httpErrorResponse.status === HttpStatusCode.Unauthorized) {
            if (httpErrorResponse.url?.includes(loginApiUrl)) return throwError(() => httpErrorResponse);
            else if (
                httpErrorResponse.url !== refreshApiUrl &&
                !httpErrorResponse.url?.includes(environment.accessmanager.logoutUri)
            )
                return handle401Error(req, next);
            authService.logout();
            router.navigate(["accesso"]);
            return EMPTY;
        } else {
            if (httpErrorResponse.status === HttpStatusCode.InternalServerError) {
                if (httpErrorResponse.url?.includes(userApiUrl)) {
                    authService.logout();
                    router.navigate(["accesso"]);
                    return EMPTY;
                } else errorHandler.handleError(httpErrorResponse);
            }
            return throwError(() => httpErrorResponse);
        }
    }

    return next(req).pipe(
        map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                loadingService.setLoading(false, req.url);
            }
            return event;
        }),
        catchError(errorCatcher)
    );
};

function addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set("Authorization", "Bearer " + token) });
}
