import { Component, OnInit } from "@angular/core";
import { FormGroup, ReactiveFormsModule, UntypedFormControl, Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import {
    ItButtonDirective,
    ItInputComponent,
    ItListComponent,
    ItListItemComponent,
    ItRadioButtonComponent,
    ItValidators,
} from "design-angular-kit";
import { AuthService } from "../../services/auth.service";
import { LoggedUser, LoggedUserRole, User, UserLogin } from "@app/models/user";
import { Router } from "@angular/router";
import { ResultList } from "../../models/resultList";
import { UppercaseDirective } from "@app/directives/uppercase.directive";

@Component({
    selector: "app-login",
    standalone: true,
    imports: [
        TranslateModule,
        ItInputComponent,
        ReactiveFormsModule,
        ItButtonDirective,
        ItListComponent,
        ItListItemComponent,
        ItRadioButtonComponent,
        UppercaseDirective,
    ],
    templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
    loginForm = new FormGroup({
        cf: new UntypedFormControl("", [Validators.required, ItValidators.taxCode]),
        utenteEnteRuolo: new UntypedFormControl(null),
    });
    user: User = new User();
    roles: LoggedUserRole[] = [];

    constructor(protected authService: AuthService, private router: Router) {}

    ngOnInit() {
        this.getUser();
    }

    onLogin() {
        this.loginForm.markAllAsTouched();
        if (this.loginForm.invalid) return;
        this.authService.login(this.loginForm.getRawValue() as UserLogin).subscribe({
            next: () => {
                this.getUser();
            },
        });
    }

    getUser() {
        this.authService.getUser().subscribe((res: LoggedUser) => {
            if (res.utente.cf) {
                this.user = res.utente;
                if (res.cambioRuolo && !this.loginForm.value.utenteEnteRuolo) {
                    this.loginForm.patchValue({ cf: this.user.cf });
                    this.loginForm.get("cf")?.disable();
                    this.loginForm.get("utenteEnteRuolo")?.addValidators(Validators.required);
                    this.authService.getUserRoles().subscribe((res: ResultList<LoggedUserRole>) => {
                        this.roles = res.risultati.map((role) => Object.assign(new LoggedUserRole(), role));
                    });
                } else if (res.utenteEnteRuolo) this.router.navigate(["/"]);
            }
        });
    }
}
