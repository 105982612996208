<app-header></app-header>
<main>
    <section class="section-head"></section>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-lg-lg-12 py-3">
                <it-breadcrumb>
                    <it-breadcrumb-item class="home">Home</it-breadcrumb-item>
                    @for (item of items; track $index) {
                    <it-breadcrumb-item [href]="item.link">{{ item.label }}</it-breadcrumb-item>
                    }
                </it-breadcrumb>
            </div>
        </div>
    </div>
    <router-outlet></router-outlet>
    <it-notifications></it-notifications>
    @if (loading) {<it-spinner></it-spinner>}
</main>
<app-footer></app-footer>
