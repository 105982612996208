<it-header
    slimImg="../assets/logo.svg"
    slimTitle="{{ 'it.portal.ras.name' | translate }}"
    slimTitleLink="https://www.regione.sardegna.it"
    loginStyle="custom"
    [light]="true"
    [showSearch]="false"
    [sticky]="true"
>
    <ng-container login
        >@if(authService.isUserLoggedIn && loggedUser.utente) {
        <it-dropdown class="login-button">
            <span button class="d-flex align-items-md-center">
                <span class="material-symbols-outlined me-2"> account_circle </span
                ><span class="me-1">{{ loggedUser.utente.nome }} {{ loggedUser.utente.cognome }}</span>
            </span>
            <ng-container list>
                @if(loggedUser.utenteEnteRuolo){
                <div class="role-item">
                    <div class="role-text" [class]="{ 'pb-0': loggedUser.cambioRuolo }">
                        <div class="fw-semibold">{{ loggedUser.utenteEnteRuolo.ruolo | roleName | async }}</div>
                        @if(!isTechnician){
                        {{ loggedUser.utenteEnteRuolo.ente | institutionName | async }}
                        }
                    </div>
                    @if(loggedUser.cambioRuolo){
                    <it-dropdown-item (click)="onChangeRole()">{{
                        "it.portal.users.change-role" | translate
                    }}</it-dropdown-item>
                    }
                </div>
                }
                <it-dropdown-item [routerLink]="['/profilo', loggedUser.utente.uid]">{{
                    "it.portal.users.profile" | translate
                }}</it-dropdown-item>
                <it-dropdown-item (click)="onLogout()">{{ "it.portal.logout" | translate }}</it-dropdown-item>
            </ng-container>
        </it-dropdown>
        } @else {
        <div class="login-button">
            <a itButton="primary" class="btn-full d-flex align-items-md-center" routerLink="accesso">
                <span class="material-symbols-outlined me-2"> account_circle </span
                ><span class="me-1">{{ "it.portal.login.label" | translate }}</span>
            </a>
        </div>
        }
    </ng-container>
    <ng-container brand>
        <a href="/">
            <div class="it-brand-text">
                <div class="it-brand-title">{{ "it.portal.name" | translate }}</div>
            </div>
        </a>
    </ng-container>
    @if(authService.isUserLoggedIn && loggedUser.utenteEnteRuolo) {
    <ng-container navItems>
        @if(authService.hasAllQualifications([QualificationType.GET_PROPERTIES]) | async){
        <it-navbar-item>
            <a class="nav-link text-decoration-none" routerLink="immobili" routerLinkActive="active">
                <span class="material-icons-outlined me-2">home_work</span>
                <span>{{ "it.portal.menu.properties" | translate }}</span></a
            >
        </it-navbar-item>
        } @if(authService.hasAllQualifications([QualificationType.GET_FOLDERS]) | async){
        <it-navbar-item>
            <a class="nav-link text-decoration-none" routerLink="fascicoli" routerLinkActive="active">
                <span class="material-symbols-outlined me-2">stacked_inbox</span>
                <span>{{ "it.portal.menu.folders" | translate }}</span></a
            >
        </it-navbar-item>
        } @if(authService.hasAllQualifications([QualificationType.GET_INSTITUTIONS]) | async){
        <it-navbar-item>
            <a class="nav-link text-decoration-none" routerLink="enti" routerLinkActive="active">
                <span class="material-symbols-outlined me-2">shield_person</span>
                <span>{{ "it.portal.menu.institutions" | translate }}</span></a
            >
        </it-navbar-item>
        } @if(authService.hasAllQualifications([QualificationType.GET_USERS, QualificationType.MANAGE_USERS]) | async){
        <it-navbar-item>
            <a class="nav-link text-decoration-none" routerLink="utenti" routerLinkActive="active">
                <span class="material-icons-outlined me-2">manage_accounts</span>
                <span>{{ "it.portal.menu.users" | translate }}</span></a
            >
        </it-navbar-item>
        }
        <!-- <it-navbar-item>
            <a class="nav-link text-decoration-none" routerLink="configurazioni" routerLinkActive="active">
                <span class="material-icons-outlined me-2">dashboard</span>
                <span>{{ "it.portal.menu.configurations" | translate }}</span></a
            >
        </it-navbar-item> -->
    </ng-container>
    }
</it-header>
