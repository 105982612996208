import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from "@angular/router";
import { ItHeaderComponent } from "@app/components/custom/header/header.component";
import { LoggedUser, User, UserLogin } from "@app/models/user";
import { AuthService } from "@app/services/auth.service";
import { TranslateModule } from "@ngx-translate/core";
import {
    ItButtonDirective,
    ItDropdownComponent,
    ItDropdownItemComponent,
    ItNavBarItemComponent,
} from "design-angular-kit";
import { RoleNamePipe } from "@app/pipes/role.pipe";
import { InstitutionNamePipe } from "@app/pipes/institution.pipe";
import { DecoStoreService } from "@app/stores/deco.store";
import { AsyncPipe } from "@angular/common";
import { QualificationType, RoleType } from "@app/models/role";
import { RoutingService } from "@app/services/routing.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-header",
    standalone: true,
    imports: [
        ItHeaderComponent,
        ItNavBarItemComponent,
        ItDropdownComponent,
        ItDropdownItemComponent,
        ItButtonDirective,
        TranslateModule,
        RouterLink,
        RouterLinkActive,
        RoleNamePipe,
        InstitutionNamePipe,
        AsyncPipe,
    ],
    templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit {
    loggedUser: LoggedUser = new LoggedUser();
    QualificationType = QualificationType;
    currentRoute: string | null = null;
    isSticky: boolean = false;

    constructor(protected authService: AuthService, private router: Router, private routingService: RoutingService) {}

    get isTechnician() {
        return this.loggedUser.isTechnician;
    }

    get bypassAccessManager() {
        return environment.accessmanager.bypass;
    }

    ngOnInit() {
        this.routingService.currentRoute$.subscribe((currentRoute) => {
            this.currentRoute = currentRoute;
        });
        this.authService.getUser().subscribe((loggedUser) => {
            this.loggedUser = new LoggedUser(loggedUser);
        });
    }

    onChangeRole() {
        this.router.navigate(["accesso"]);
    }

    onLogin() {
        this.currentRoute === "accesso" ? location.reload() : this.router.navigate(["accesso"]);
    }

    onLogout() {
        this.authService.logoutAccessManager().subscribe();
        this.authService.logout();
        this.currentRoute === "accesso" ? location.reload() : this.router.navigate(["accesso"]);
    }

    onStickyChange(isSticky: boolean): void {
        this.isSticky = isSticky;
    }
}
