<footer class="it-footer mt-5" id="footer">
    <div class="it-footer-main">
        <div class="container">
            <section class="px-0">
                <div class="row">
                    <div class="col-12 footer-items-wrapper logo-wrapper">
                        <div class="it-brand-wrapper">
                            <a href="/">
                                <picture>
                                    <img
                                        src="../assets/logo-bianco.svg"
                                        title="{{ 'it.portal.ras.alt-img' | translate }}"
                                        alt="{{ 'it.portal.ras.alt-img' | translate }}"
                                        height="72"
                                    />
                                </picture>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section class="px-0 pb-4">
                <div class="row">
                    <div class="col-md-6 footer-items-wrapper">
                        <h4 class="h4 footer-heading-title">
                            <a>{{ "it.footer.contatti" | translate }}</a>
                        </h4>
                        <hr />
                        <p class="lh-sm fs-6" [innerHtml]="'it.footer.servizio' | translate"></p>
                        <p class="lh-sm fs-6">
                            <span [innerHtml]="'it.footer.indirizzo' | translate"></span>
                            <span [innerHtml]="'it.footer.email' | translate"></span>
                            <span [innerHtml]="'it.footer.pec' | translate"></span>
                        </p>
                    </div>
                    <div class="col-md-3 footer-items-wrapper">
                        <h4 class="h4 footer-heading-title">
                            <a class="invisible">{{ "it.footer.link" | translate }}</a>
                        </h4>
                        <hr />
                        <div class="link-list-wrapper">
                            <ul class="footer-list link-list">
                                <li>
                                    <a
                                        class="list-item"
                                        href="/informativa-privacy/"
                                        title="{{ 'it.portal.goto-page' | translate }}{{
                                            'it.footer.links.privacy' | translate
                                        }}"
                                    >
                                        {{ "it.footer.links.privacy" | translate }}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        class="list-item"
                                        href="/note-legali/"
                                        title="{{ 'it.portal.goto-page' | translate }} {{
                                            'it.footer.links.note-legali' | translate
                                        }}"
                                    >
                                        {{ "it.footer.links.note-legali" | translate }}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        class="list-item"
                                        href="https://form.agid.gov.it/view/xxxxxx"
                                        title="{{ 'it.footer.links.accessibilita' | translate }}"
                                    >
                                        {{ "it.footer.links.accessibilita" | translate }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3 footer-items-wrapper">
                        <h4 class="h4 footer-heading-title">
                            <a>{{ "it.footer.seguici" | translate }}</a>
                        </h4>
                        <hr />
                        <ul class="list-inline text-left social">
                            <li class="list-inline-item">
                                <a href="https://www.facebook.com/profile.php?id=xxxxxx" target="_blank">
                                    <it-icon name="facebook" class="bg-dark" color="white" size="sm"></it-icon>
                                    <span class="visually-hidden">{{ "it.footer.social.facebook" | translate }}</span>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="https://www.instagram.com/xxxxxx/" target="_blank">
                                    <it-icon name="instagram" class="bg-dark" color="white" size="sm"></it-icon>
                                    <span class="visually-hidden">{{ "it.footer.social.instagram" | translate }}</span>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="https://www.youtube.com/channel/xxxxxx" target="_blank">
                                    <it-icon name="youtube" class="bg-dark" color="white" size="sm"></it-icon>
                                    <span class="visually-hidden">{{ "it.footer.social.youtube" | translate }}</span>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a>
                                    <it-icon name="rss" class="bg-dark" color="white" size="sm"></it-icon>
                                    <span class="visually-hidden">{{ "it.footer.rss" | translate }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section class="px-0">
                <div class="row">
                    <div class="col-md-12 footer-items-wrapper">
                        <hr />
                        <p class="fs-6">© 2024 {{ "it.portal.ras.name" | translate }}</p>
                    </div>
                </div>
            </section>
        </div>
    </div>
</footer>
